import React from "react";
import { getDate } from "../../utils/helper";
import "./style.css";

import { useTranslation } from "react-i18next";
import OrderOverView from "../order-overview/OrderOverView";

const SubscriptionDetails = ({
  amount,
  billingCycleType,
  ccy,
  planName,
  subscriptionEndsAt,
  subscriptionSource,
  subscriptionStartsAt,
  subscriptionType,
  trialPeriodDuration = "",
  trialPeriodDurationType = "",
  planDiscountPercentage = "",
  planDiscountDuration = "",
  comments,
  billingDate,
  nextBillingDate
}: any) => {
  const { t } = useTranslation();

  function convertToProperCase(inputString: string) {
    // Convert the string to lowercase and split it into an array of words
    let words = inputString?.toLowerCase()?.split(" ");

    // Capitalize the first letter of each word
    words = words?.map(
      (word: any) => word?.charAt(0)?.toUpperCase() + word?.slice(1)
    );

    // Join the words back into a string
    return words.join("");
  }
  const subscriptionData = [
    {
      label: "Subscription.label.PlanName",
      value: planName,
    },
    {
      label: "Subscription.label.BillingCycle",
      value: convertToProperCase(billingCycleType),
    },
    {
      label: "Subscription.label.StartBillingDate",
      value: getDate(subscriptionStartsAt),
    },
    {
      label: "Subscription.label.BillingDate",
      value: billingDate,
    },
    {
      label: "Subscription.label.NextBillingDate",
      value: nextBillingDate,
    },
    {
      label: "Subscription.label.EndBillingDate",
      value: subscriptionEndsAt ? getDate(subscriptionEndsAt) : "",
    },
    {
      label: "Subscription.label.TrialPeriod",
      value:
        trialPeriodDuration && trialPeriodDurationType
          ? `${trialPeriodDuration} ${convertToProperCase(
              trialPeriodDurationType
            )}`
          : "",
    },
    {
      label: "Subscription.label.Discount",
      value:
        planDiscountPercentage && planDiscountDuration
          ? ` ${t("Subscription.value.ForTheFirst")} ${planDiscountDuration} ${
              Number(planDiscountDuration) > 1
                ? `${t("Subscription.value.months")}`
                : `${t("Subscription.value.month")}`
            } ${planDiscountPercentage}% ${t("Subscription.value.Discount")} `
          : "",
    },
    {
      label: "Subscription.label.Comment",
      value: comments || " ",
    },
  ];
  return (
    <div className="susbcriptionPopup">
      <OrderOverView ccy={ccy} amount={amount} />

      <div className="subscription-details">
        {subscriptionData.map((item: any, index: number) => {
          return item?.value && (
            <div className="subscription-flex" key={index}>
              <strong className="plan-heading">{t(item?.label)}:</strong>
              <span className="subscription-value">{item?.value}</span>
            </div>
          ) 
        })}
      </div>
    </div>
  );
};

export default SubscriptionDetails;
