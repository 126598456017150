import React, { useEffect } from "react";
import { CARDINAL_URL } from "../../constants/api-path.c";
const JwtFrame = ({ jwt, bin }: { jwt: any; bin: any }) => {
  useEffect(() => {
    const iframe = document.getElementById("formFrame");
    if (iframe) {
      const iframe = document.getElementById("formFrame") as HTMLIFrameElement;

      if (iframe && iframe.contentDocument) {
        const iframeDoc = iframe.contentDocument;

        if (!iframeDoc.getElementById("jwtForm")) {
          // Create the form within the iframe
          const form = iframeDoc.createElement("form");
          form.method = "POST";
          form.action = `${CARDINAL_URL}/V1/Cruise/Collect`;

          // Add hidden input fields to the form
          const inputName = iframeDoc.createElement("input");
          inputName.type = "hidden";
          inputName.name = "JWT";
          inputName.value = jwt;
          form.appendChild(inputName);

          const inputValue = iframeDoc.createElement("input");
          inputValue.type = "hidden";
          inputValue.name = "Bin";
          inputValue.value = bin;
          form.appendChild(inputValue);

          // Append the form to the iframe's body
          iframeDoc.body.appendChild(form);

          form.submit();
        }
      }
    }
  }, [jwt, bin]);

  return <iframe id="formFrame" title="Form Frame" style={{ width: "1px", height: "1px", display: "none" }}></iframe>;
};

export default JwtFrame;