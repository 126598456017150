export const ERROR_PAGE = "HostedPaymentPage/result/error"
export const BAD_REQUEST_PAGE = "HostedPaymentPage/result/error/400"
export const NOT_FOUND_PAGE = "HostedPaymentPage/result/error/404"
export const SERVER_ERROR_PAGE = "HostedPaymentPage/result/error/500"
export const CANCEL_PAGE = "HostedPaymentPage/result/error/cancel"
export const SUCCESS_PAGE = "HostedPaymentPage/result/success"
export const FAILED_PAGE_400 = "HostedPaymentPage/result/error/400/:failed"
export const FAILED_PAGE_500 = "HostedPaymentPage/result/error/500/:failed"





export const INTEGRATION_PAGE_3DS = "integration/3ds"

