import React, { useEffect, useState } from "react";
import JwtFrame from "../../components/integration/JwtFrame";
import RequestChallengePage from "../../components/integration/requestChallengePage";
import { BASE_API_URL } from "../../constants/api-path.c";
import Api from "../../utils/Api";
import { performRCP } from "../../utils/helper";
import RedirectNomupayHandler from "../../components/redirect-to-3ds/RedirectToNomupay3DS";
import RedirectTo3DS from "../../components/redirect-to-3ds/RedirectTo3DS";
import { BAD_REQUEST_PAGE, SERVER_ERROR_PAGE, SUCCESS_PAGE } from "../../constants/path.c";
import { useNavigate } from "react-router-dom";
import { isJapanese } from "../../components/hooks/isJapanese";
import Loader from "../../components/loader/Loader.component";

const IntegrationPage = () => {
  const navigate = useNavigate();
  const japanese = isJapanese();
  //const [paymentResponse, setPaymentResponse] = useState<any>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [binNo, setBinNo] = useState<string | null>(null);
  const [binidentifier, setBinidentifier] = useState<string | null>(null);
  const [nomupayContent, setNomupayContent] = useState();
  const [htmlContent, setHtmlContent] = useState("");
  const [isCardinalAPIExecuted, setCardinalAPIExecuted] = useState(false);
  let redirectURL = "", isRedrectRequired = false;
  useEffect(() => {

    const jToken = queryParams.get("_token");
    const cardNumber = queryParams.get("_c");
    const _a: any = queryParams.get("_a")
    const binidentifier = atob(_a);
    setJwtToken(jToken);
    setBinNo(cardNumber);
    setBinidentifier(binidentifier);

    const CARDINAL_API_URL = `${BASE_API_URL}/paymentv2/set-session`;

    const messageHandler = async (event: MessageEvent) => {


      // This is a Cardinal Commerce URL in live.
      if (event.origin === process.env.REACT_APP_PUBLIC_CARDINAL_URL) {
        try {
          const data = JSON.parse(event.data);

          if (data && data.Status) {

            const paymentSessionID = data.SessionId;
            const paymentJSON = {
              "cardinal_session_id": paymentSessionID,
              "jwtToken": jwtToken
            };
            if (jwtToken && !isCardinalAPIExecuted) {
              await Api().post(CARDINAL_API_URL, paymentJSON).then((result: any) => {
                setCardinalAPIExecuted(true)
                if (result?.jwt && result?.MID) {
                  isRedrectRequired = true;
                  performRCP(result?.jwt, result?.MID);
                }
                else {
                  const transactionID = result?.Ref;
                  //handle direct payment redirection to success/decline url
                  let redirect = result?.direct_payment?.redirect_url;
                  if (redirect) {
                    isRedrectRequired = true;
                    window.location.href = redirect;
                    return;
                  } else {
                    if (result.success) {
                      redirectURL = `/${SUCCESS_PAGE}/${transactionID}`;

                    } else {
                      if (result.hasOwnProperty("success") && result.success === false) {
                        if (transactionID) {
                          redirectURL = `/${SERVER_ERROR_PAGE}/${transactionID}`;
                        } else {
                          redirectURL = `/${SERVER_ERROR_PAGE}`;
                        }
                      } else {
                        redirectURL = `/${BAD_REQUEST_PAGE}`;
                      }
                    }
                  }



                }




              });
            }

          }
        } catch (error) {
          redirectURL = `/${SERVER_ERROR_PAGE}`;

        }
        finally {
          if (!isRedrectRequired) {
            if (japanese) {
              navigate(`/jp${redirectURL}`);
            } else {
              navigate(`${redirectURL}`);
            }
          }
        }
      }
    };

    if (binidentifier == "WORLDPAY") {
      // Add the event listener
      window.addEventListener("message", messageHandler);
    }
    else {
      getAPICall(CARDINAL_API_URL);
    }

    // Cleanup on component unmount
    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [jwtToken]);

  const getAPICall = (URL: string) => {

    const paymentJSON = {
      "cardinal_session_id": "",
      "jwtToken": jwtToken || queryParams.get("_token")
    };
    if (jwtToken && !isCardinalAPIExecuted) {
      setCardinalAPIExecuted(true)
      Api().post(URL, paymentJSON).then((result: any) => {
        try {
          if (result?.redirecturl && result?.additional_parameters) {
            setNomupayContent(result);
            //setDasLoader(false);
          }
          else if (result?.redirecturl) {
            window.location.href = result.redirecturl;
            //window.open(result.redirecturl, "_blank");
          }
          else {
            const transactionID = result?.Ref;
            //handle direct payment redirection to success/decline url
            let redirect = result?.direct_payment?.redirect_url;
            if (redirect) {
              isRedrectRequired = true;
              window.location.href = redirect;
              return;
            } else {
              if (result.success) {
                redirectURL = `/${SUCCESS_PAGE}/${transactionID}`;

              } else {
                if (result.hasOwnProperty("success") && result.success === false) {
                  if (transactionID) {
                    redirectURL = `/${SERVER_ERROR_PAGE}/${transactionID}`;
                  } else {
                    redirectURL = `/${SERVER_ERROR_PAGE}`;
                  }
                } else {
                  redirectURL = `/${BAD_REQUEST_PAGE}`;
                }
              }
            }



          }

        } catch (error) {
          redirectURL = `/${SERVER_ERROR_PAGE}`;

        }
        finally {
          if (!isRedrectRequired) {
            if (japanese) {
              navigate(`/jp${redirectURL}`);
            } else {
              navigate(`${redirectURL}`);
            }
          }
        }


      });
    }
  }

  return (
    <div style={{ width: "100%", height: "100%", display: "block" }}>
      <Loader />
      {binidentifier == "WORLDPAY" &&
        <JwtFrame jwt={jwtToken} bin={binNo} />
      }
      {nomupayContent &&
        <RedirectNomupayHandler result={nomupayContent} />
      }
      {
        htmlContent &&
        <RedirectTo3DS htmlContent={htmlContent} />
      }
    </div>
  );
};

export default IntegrationPage;

