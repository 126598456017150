import {
  SUCCESS_PAGE,
  SERVER_ERROR_PAGE,
  BAD_REQUEST_PAGE,
} from "../../constants/path.c";
import Api from "../../utils/Api";
import { encryptText, performRCP } from "../../utils/helper";
import { isJapanese } from "./isJapanese";
import { BASE_API_URL } from "../../constants/api-path.c";

export const capturePayment = async (
  allowedPayloadData: any,
  sessionID: any,
  navigate: any,
  setHtmlContent: any,
  setNomupayContent: any,
  setDasLoader: any,
  slug: any,
  isIFrame: any
) => {
  const japanese = isJapanese();
  const payloadData = {
    ...allowedPayloadData,
    dfReferenceId: encryptText(sessionID),
    browser_info: {
    language: navigator?.language || '',  // Browser language
    screenHeight: window.screen?.width,  // Screen height in pixels
    screenWidth: window.screen?.height,  // Screen width in pixels
    userAgent: navigator?.userAgent || '',  // User agent string
    javaEnabled: navigator?.javaEnabled ? navigator.javaEnabled() : false,  // Check if Java is enabled
    javascriptEnabled: true,  // Assume JS is enabled if the script is running
    screenColorDepth: window.screen?.colorDepth?.toString() || '',  // Screen color depth in bits
    }
  };
  await Api()
    //.post(`${BASE_API_URL}/capture/payment`, payloadData)
    .post(`${BASE_API_URL}/capture/payment`, payloadData)
    .then((result: any) => {
      localStorage.setItem("slug", slug);
      if (result?.redirecturl && result?.additional_parameters) {
        setNomupayContent(result);
        setDasLoader(false);
      }
      else if (result?.redirecturl) {
        window.open(result.redirecturl, "_blank");
      }
      else if (result?.returnURL) {
        window.location.href = result?.returnURL;
      }
      else if (result?.html) {
        setHtmlContent(result.html);
        setDasLoader(false);
      } else if (result?.jwt) {
        performRCP(result.jwt, result.MID);
      } else {
        // adding product type in the encoded data
        // let resultToBeEncoded;
        // if (productList.length > 0) {
        //   resultToBeEncoded = { ...result, productType: "pbl" };
        // }
        // const objJsonStr = JSON.stringify(resultToBeEncoded);
        // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
        // console.log(result, "result");
        const transactionID = result?.Ref; 
        let redirectURL;
        if (result.success) {
          if (isIFrame && result?.return_url?.success_url) {
           redirectURL=""
          } else {
            redirectURL = `/${SUCCESS_PAGE}/${transactionID}`;
          }
        } else {
          // need to ask this condition from primal as I am getting 400 error and its going to 500 page
          // same logic was there in old hpp
          if (result.hasOwnProperty("success") && result.success === false) {
            if (transactionID) {
              redirectURL = `/${SERVER_ERROR_PAGE}/${transactionID}`;
            }else{
              redirectURL = `/${SERVER_ERROR_PAGE}`;
            }
          } else {
            redirectURL = `/${BAD_REQUEST_PAGE}`;
          }
        }
        if(redirectURL===""){
          window.location.replace(result?.return_url?.success_url);
        }
        else{

          if (japanese) {
            navigate(`/jp${redirectURL}`);
          } else {
            navigate(redirectURL);
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
      // let error = stringToBase64(
      //   JSON.stringify({
      //     success: false,
      //     Merchant: COMPANY,
      //     paymentDetail: {
      //       Amount: {
      //         value: amountLabel,
      //         currencyCode: CURRENCY,
      //       },
      //       paymentMethod: "",
      //     },
      //     Ref: 0,
      //     return_url: {
      //       success_url: apiData.success_url,
      //       cancel_url: apiData.cancel_url,
      //       decline_url: apiData.decline_url,
      //     },
      //   })
      // );
      // if (japanese) {
      //   navigate(`/jp/${SERVER_ERROR_PAGE}/${error}`);
      // } else {
      //   navigate(`/${SERVER_ERROR_PAGE}/${error}`);
      // }
    });
};
